<div class="text-center p-3">
    <h1>Current Newsletter</h1>

</div>
<div class="text-center">
    <a href="../../assets/24-Aug Newsletter.pdf">Open as PDF</a>
</div>


<div class="text-center p-3">
    <img src="../../assets/img/newsletter 08_24.png" alt="">
</div>
